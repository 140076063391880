<template>
  <div class="bg-white px-8 quotation-create-page">
    <v-form
      ref="createQuotationForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="validateJob()"
    >
      <div class="py-3">
        <div class="form-action">
          <div class="form-title d-flex">
            Quotation for
            <div
              v-if="vCustomerId"
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              {{ vCustomer.display_name }}
            </div>
            <template v-else>
              <div
                class="ml-3 form-title-link cursor-pointer text-h5"
                v-on:click="openDialog('customer')"
              >
                Select a Customer
                <v-icon large color="cyan">mdi-account-check-outline</v-icon>
              </div>
              <div class="mx-3">OR</div>
              <div
                class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
                v-on:click="createCustomerDialog()"
              >
                Create a new Customer
                <v-icon large color="cyan darken-4"
                  >mdi-plus-circle-outline</v-icon
                >
              </div>
            </template>
          </div>
          <div>
            <v-chip
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3 mr-4"
              style="font-size: 21px !important; font-weight: 700"
            >
              <template>
                {{ dbQuotation.barcode }}
              </template>
            </v-chip>
            <v-btn
              :disabled="formLoading"
              v-on:click="goBack()"
              class="custom-bold-button custom-grey-border"
              depressed
              >Cancel</v-btn
            >
            <v-btn
              class="custom-bold-button ml-4"
              depressed
              :disabled="!formValid || formLoading"
              :loading="formLoading"
              v-on:click="validateJob()"
              color="cyan white--text"
              >Save</v-btn
            >
          </div>
        </div>
      </div>
      <v-row>
        <!--  <v-col md="12">
          <div class="form-action">
            <div class="form-title">
              Quotation for {{ reCustomerId }}
              <span
                class="form-title-link cursor-pointer"
                v-on:click="openDialog('customer')"
              >
                <template v-if="reCustomerId && reCustomerId > 0">{{
                  vCustomer.display_name
                }}</template>
                <template v-else>
                  Select a Customer
                  <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
                </template>
              </span>
            </div>
            <div>
              <v-chip
                label
                color="chip-custom-blue"
                outlined
                class="text-white mr-2"
                style="font-size: 21px !important; font-weight: 700"
                >{{ dbQuotation.barcode }}</v-chip
              >
              <v-btn
                :disabled="formLoading"
                v-on:click="goBack()"
                class="custom-bold-button custom-grey-border"
                depressed
                >Cancel</v-btn
              >
              <v-btn
                class="custom-bold-button ml-4"
                depressed
                :disabled="!formValid || formLoading"
                :loading="formLoading"
                v-on:click="validateJob()"
                color="cyan white--text"
                >Save</v-btn
              >
            </div>
          </div>
        </v-col> -->
        <v-col md="7">
          <label for="quotation-title" class="required">Quotation Title</label>
          <TextField
            id="quotation-title"
            dense
            filled
            placeholder="Title"
            solo
            flat
            counter="50"
            v-model="dbQuotation.title"
            color="cyan"
            :maxlength="250"
            :rules="[validateRules.required(dbQuotation.title, 'Title')]"
          />

          <TextAreaField
            auto-grow
            dense
            filled
            color="cyan"
            v-model="dbQuotation.description"
            placeholder="Description"
            solo
            flat
            row-height="25"
            counter="250"
          />
          <v-row>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3
                  ><label class="my-0">Billing Location</label></v-flex
                >
                <v-flex md9 class="position-relative">
                  <v-icon
                    v-on:click="openDialog('billing')"
                    small
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBilling?.id">
                <span v-if="vBilling && vBilling.property_address">
                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                >
                <p v-if="vBilling && vBilling.property_address">
                  {{ vBilling.street_1 }},
                  <br v-if="vBilling.street_2 || vBilling.unit_no" />
                  <template v-if="vBilling.street_2">
                    {{ vBilling.street_2 }},
                  </template>
                  <template v-if="vBilling.unit_no">
                    {{ vBilling.unit_no }},
                  </template>
                  <br />
                  {{ vBilling.country }}
                  <template v-if="vBilling && vBilling.zip_code != '000000'">
                    , {{ vBilling.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select billing location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('billing-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBillingContact?.id">
                <p class="mb-0">{{ vBillingContact.display_name }}</p>
                <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                <p class="mb-0">{{ vBillingContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout v-if="false" class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbQuotation.notify_billing_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3><label class="my-0">Site Location</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vProperty?.id">
                <span v-if="vProperty && vProperty.property_address">
                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                >
                <p class="mr-2" v-if="vProperty && vProperty.property_address">
                  {{ vProperty.street_1 }},
                  <br v-if="vProperty.street_2 || vProperty.unit_no" />
                  <template v-if="vProperty.street_2">
                    {{ vProperty.street_2 }},
                  </template>
                  <template v-if="vProperty.unit_no">
                    {{ vProperty.unit_no }},
                  </template>
                  <br />
                  {{ vProperty.country }}
                  <template v-if="vProperty && vProperty.zip_code != '000000'">
                    , {{ vProperty.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select service location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vPropertyContact?.id">
                <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout v-if="false" class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbQuotation.notify_property_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="5">
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="150" class="font-weight-600">
                <label>Rate opportunity</label>
              </td>
              <td class="font-weight-700">
                <v-rating
                  :readonly="formLoading"
                  v-model.trim="dbQuotation.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="reference-no"
                  ><!-- C/O or Customer Ref/Your Ref on PDF -->
                  Customer Reference # (For PDF as Your Ref.)</label
                >
              </td>
              <td>
                <v-text-field
                  id="reference-no"
                  v-model.trim="dbQuotation.reference"
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  placeholder="Customer Reference #"
                  :rules="[
                    validateRules.minLength(
                      dbQuotation.reference,
                      'Customer Reference',
                      1
                    ),
                    validateRules.maxLength(
                      dbQuotation.reference,
                      'Customer Reference',
                      100
                    ),
                  ]"
                  solo
                  flat
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="your-reference-no">Internal Reference #</label>
              </td>
              <td>
                <v-text-field
                  id="your-reference-no"
                  v-model.trim="dbQuotation.your_reference"
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  placeholder="Internal Reference #"
                  :rules="[
                    validateRules.minLength(
                      dbQuotation.your_reference,
                      'Internal Reference',
                      1
                    ),
                    validateRules.maxLength(
                      dbQuotation.your_reference,
                      'Internal Reference',
                      100
                    ),
                  ]"
                  solo
                  flat
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="sales-representatives">Sales representatives</label>
              </td>
              <td>
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="sales-representatives"
                  :items="userList"
                  :disabled="formLoading"
                  :loading="formLoading"
                  v-model.trim="dbQuotation.sales_executive"
                  placeholder="Sales representatives"
                  solo
                  flat
                  item-color="cyan"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Sales Representative Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="prepared-by" class="required">Prepared by</label>
              </td>
              <td>
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="prepared-by"
                  :items="userList"
                  :disabled="formLoading"
                  :loading="formLoading"
                  :rules="[
                    validateRules.required(
                      dbQuotation.prepared_by_id,
                      'Prepared by'
                    ),
                  ]"
                  v-model.trim="dbQuotation.prepared_by_id"
                  placeholder="Prepared by"
                  solo
                  flat
                  item-color="cyan"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No User Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="prepared-by" class="required">Validity </label>
              </td>
              <td>
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="prepared-by"
                  :items="validPeriod"
                  :rules="[
                    validateRules.required(
                      dbQuotation.validity_till,
                      'Validity'
                    ),
                  ]"
                  :disabled="formLoading"
                  :loading="formLoading"
                  v-model.trim="dbQuotation.validity_till"
                  placeholder="validity"
                  solo
                  flat
                  item-color="cyan"
                  item-text="text"
                  item-value="value"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No User Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td width="150" class="font-weight-600">
                <label for="tags">Tags </label>
              </td>
              <td>
                <tag-auto-complete
                  v-if="related_type"
                  :type="tag_type"
                  v-model="dbQuotation.tags"
                  placeholder="tags"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="type">Type</label>
              </td>
              <td>
                <div class="d-flex ml-1">
                  <v-btn
                    depressed
                    tile
                    :outlined="!(dbQuotation.type == 1)"
                    v-on:click="
                      dbQuotation.type = 1 /*  changeContractStatus() */
                    "
                    value="1"
                    color="light-green darken-3 white--text"
                    >New</v-btn
                  >
                  <v-btn
                    class="mx-2"
                    depressed
                    tile
                    :outlined="!(dbQuotation.type == 2)"
                    v-on:click="
                      dbQuotation.type = 2 /*  changeContractStatus() */
                    "
                    value="2"
                    color="orange darken-4 white--text"
                    >Maintenance</v-btn
                  >
                </div>
              </td>
            </tr>
          </table>
        </v-col>
        <v-col
          cols="12"
          v-if="dbQuotation.type == 2 && !$route?.query?.edit && false"
          ref="qtContract"
        >
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3
                class="font-weight-700 custom-headline color-custom-blue d-flex align-center"
              >
                Contract
                <v-checkbox
                  v-if="false"
                  color="cyan"
                  hide-details
                  class="ml-5 py-0 my-0"
                  :value="contract_status"
                  v-on:change="changeContractStatus()"
                />
              </h3>
            </v-card-title>
            <v-card-text v-if="contract_status" class="p-6 font-size-16">
              <div>
                <recurring-schedule
                  :key="`contract-recurring-schedule-${schedule_key}`"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" ref="qtLineItem">
          <v-container fluid>
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title
                class="headline grey lighten-4"
                @click="show_line_items = !show_line_items"
              >
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  <v-icon>{{
                    !show_line_items ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                  Line Items
                </h3>
              </v-card-title>
              <v-expand-transition>
                <v-card-text
                  v-show="show_line_items"
                  ref="lineItem"
                  class="font-size-16 py-0"
                >
                  <line-item
                    v-if="related_type > 0"
                    :related-type="related_type"
                    is-job
                    :related-detail="updatedQuotationData"
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                  />
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </v-container>
        </v-col>
        <v-col cols="12" ref="qtNote">
          <v-container fluid>
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title
                class="headline grey lighten-4"
                @click="show_notes_attachment = !show_notes_attachment"
              >
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  <v-icon>{{
                    !show_notes_attachment
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                  INTERNAL NOTES &amp; ATTACHMENTS
                </h3>
              </v-card-title>
              <v-expand-transition>
                <v-card-text
                  class="p-6 font-size-16"
                  v-show="show_notes_attachment"
                >
                  <v-layout>
                    <v-flex class="mr-4" md6>
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <!--  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Internal Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="formLoading"
                    :loading="formLoading"
                    :items="adminNoteList"
                    v-model="dbQuotation.admin_note_id"
                    v-on:change="update_admin_note()"
                  >
                  </v-autocomplete> -->
                      <tiny-mce
                        :disabled="formLoading"
                        v-model="dbQuotation.admin_note"
                      />
                    </v-flex>
                    <v-flex md6>
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                        <!-- <span class="text--secondary font-weight-500"
                      >(visible on PDF)</span
                    > --></label
                      >
                      <!--  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Client Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="formLoading"
                    :loading="formLoading"
                    :items="clientNoteList"
                    v-model="dbQuotation.client_note_id"
                    v-on:change="update_client_note()"
                  >
                  </v-autocomplete> -->
                      <tiny-mce
                        :disabled="formLoading"
                        v-model="dbQuotation.client_note"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-4">
                    <v-flex md12 class="mr-4">
                      <label class="font-size-16 font-weight-600"
                        >Attachments</label
                      >
                      <file-upload
                        v-model="dbQuotation.attachments"
                        :maxLimit="5"
                      >
                      </file-upload>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </v-container>
        </v-col>
        <v-col cols="12" ref="qtTermsConditions">
          <v-container fluid>
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title
                class="headline grey lighten-4"
                @click="show_terms_condition = !show_terms_condition"
              >
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  <v-icon>{{
                    !show_terms_condition
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                  TERMS &amp; CONDITIONS
                </h3>
              </v-card-title>
              <v-expand-transition>
                <v-card-text
                  class="p-6 font-size-16"
                  v-show="show_terms_condition"
                >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Terms &amp; Conditions"
                    solo
                    flat
                    class="mb-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="formLoading"
                    :loading="formLoading"
                    :items="termsConditionList"
                    v-model="dbQuotation.term_condition_id"
                    v-on:change="update_term_condition()"
                  />
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="dbQuotation.term_conditions"
                  />
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <customer-select :dialog="reCustomerDialog" @close="closeCustomerDialog" />
    <customer-create :key="`quotation-customer-create-${dialog_key}`" />
    <person-select
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="contactType"
    />
    <address-select
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      :type="propertyType"
      :label="propertyLabel"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
/* import DatePicker from "@/view/components/QDatePicker.vue"; */
import LineItemV2 from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import {
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  RESET_CREATE_STATE,
} from "@/core/services/store/visit.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
/* import { SET_UOM, SET_GST, SET_EDIT_LINE_ITEM, SET_EDIT_CALCULATION, CLEAR_LINE_ITEM } from "@/core/services/store/line.item.module"; */
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
/* import RecurringSchedule from "@/view/pages/quotation/Contract/RecurringSchedule.vue"; */
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import FileUpload from "@/view/components/app-component/FileInput.vue";
import TagAutoComplete from "@/view/components/TagAutoCompleteInput.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin],
  data() {
    return {
      schedule_key: Number(new Date()),
      dialog_key: Number(new Date()),
      formLoading: false,
      formValid: true,
      show_line_items: false,
      show_notes_attachment: false,
      show_terms_condition: false,
      related_type: 0,
      tag_type: null,
      validPeriod: [
        { value: 15, text: "15 Days" },
        { value: 30, text: "30 Days" },
        { value: 60, text: "60 Days" },
        { value: 90, text: "90 Days" },
      ],
      dbQuotation: {
        barcode: null,
        title: null,
        description: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        type: 1,
        tags: [],
        /*   date: null, */
        prepared_by_id: null,
        sales_executive: null,
        reference: null,
        open_till: null,
        validity_till: null,
        /*   sale_by: null, */
        /*     customer_ref: null, */
        your_reference: null,
        /*   quote_by: null, */
        term_condition_id: 0,
        term_conditions: null,
        /*   admin_note_id: 0, */
        admin_note: null,
        /*  client_note_id: 0, */
        client_note: null,
        attachments: [],
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      userList: [],
      dbLineItems: [],
      dbEquipments: [],
      todayDate: null,
      updatedQuotationData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      contract_status: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    /*  "recurring-schedule": RecurringSchedule, */
    "line-item": LineItemV2,
    /*  "date-picker": DatePicker, */
    "tiny-mce": TinyMCE,
    "file-upload": FileUpload,
    "tag-auto-complete": TagAutoComplete,
    TextAreaField,
  },
  methods: {
    changeContractStatus() {
      this.schedule_key = Number(new Date());
      this.$nextTick(() => {
        this.contract_status = this.dbQuotation.type == 2;
      });
    },
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbQuotation.term_condition_id,
      });
      if (output) {
        this.dbQuotation.term_conditions = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      const allHeaders = this.vSelectedLineItem.every(
        (obj) => obj.type === "header"
      );
      if (
        !this.vSelectedLineItem ||
        !this.vSelectedLineItem.length ||
        allHeaders
      ) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,
        /*  contract: {
           dates: this.contractScheduleOutput,
           schedule: this.contractRecurringSchedule
         }, */
        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        /*  is_contract: + this.contract_status, */
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        calculation: this.vCalculations,
        "line-item": this.vSelectedLineItem,
      };

      let requestType = POST;
      let requestURL = "quotation-v1";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PATCH;
        requestURL = `quotation-v1/${quotationId}`;
      }

      this.formLoading = true;

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(() => {
          this.$store.dispatch(CLEAR_CUSTOMER);
          /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
          this.$nextTick(() => {
            this.$router.push(this.getDefaultRoute("quotation"));
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "quotation/options",
          data: {
            quotation: ObjectPath.get(this.$route, "query.edit"),
            duplicate: ObjectPath.get(this.$route, "query.duplicate"),
            revise: ObjectPath.get(this.$route, "query.revise"),
            requote: ObjectPath.get(this.$route, "query.requote"),
            enquiry: ObjectPath.get(this.$route, "query.enquiry"),
          },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );
          this.termsConditionList = ObjectPath.get(
            response,
            "data.term_condition_list",
            []
          );

          let defaultTermConditionObject = this.lodash.find(
            this.termsConditionList,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.dbQuotation.term_condition_id = defaultTermConditionObject.id;
            this.dbQuotation.term_conditions =
              defaultTermConditionObject.description;
          }

          let defaultClientObject = this.lodash.find(this.clientNoteList, {
            primary: 1,
          });
          if (defaultClientObject) {
            this.dbQuotation.client_note_id = defaultClientObject.id;
            this.dbQuotation.client_note = defaultClientObject.description;
          }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbQuotation.admin_note_id = defaultAdminObject.id;
            this.dbQuotation.admin_note = defaultAdminObject.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);

          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );
          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.quotation");
          let duplicateQuotation = ObjectPath.get(response, "data.duplicate");
          let requoteQuotation = ObjectPath.get(response, "data.requote");
          let reviseQuotation = ObjectPath.get(response, "data.revise");
          let enquiryQuotation = ObjectPath.get(response, "data.enquiry");
          if (dbQuotation?.id) {
            /*  console.log({dbQuotation}) */
            this.dbQuotation.barcode = dbQuotation?.barcode;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.quotation"
            );
            this.dbQuotation.title = dbQuotation?.title;
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.your_reference = dbQuotation?.your_reference;
            this.dbQuotation.reference = dbQuotation?.reference;
            this.dbQuotation.prepared_by_id = dbQuotation?.prepared_by_id;
            this.dbQuotation.sales_executive = dbQuotation?.sales_executive;
            this.dbQuotation.open_till = dbQuotation?.open_till;
            this.dbQuotation.validity_till = dbQuotation?.validity_till;
            this.dbQuotation.type = dbQuotation?.type;
            this.dbQuotation.tags = dbQuotation?.tags;
            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = dbQuotation?.rating;
            /* this.dbQuotation.attachments = dbQuotation?.attachments ?? []; */

            this.dbQuotation.notify_billing_contact_person =
              dbQuotation?.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              dbQuotation?.notify_property_contact_person;

            this.dbQuotation.client_note_id = dbQuotation?.client_note_id;
            this.dbQuotation.client_note = dbQuotation?.client_note;

            this.dbQuotation.term_condition_id = dbQuotation?.term_condition_id;
            this.dbQuotation.term_conditions = dbQuotation?.term_conditions;

            this.dbQuotation.admin_note_id = dbQuotation?.admin_note_id;
            this.dbQuotation.admin_note = dbQuotation?.admin_note;

            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: dbQuotation?.ctx_discount_label ?? null,
                ctx_discount_value: dbQuotation?.ctx_discount_value ?? 0,
                ctx_discount_type: dbQuotation?.ctx_discount_type ?? 1,
                discount_type: dbQuotation?.discount_type ?? 1,
                discount_value: dbQuotation?.discount_value ?? 0,
                tax_active: dbQuotation?.tax_active ?? false,
                adjustment: dbQuotation?.adjustment ?? 0,
                show_price: true,
                discount_level: dbQuotation?.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, dbQuotation?.line_item ?? []); */

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Quotation", route: "quotation" },
              { title: "Update" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (duplicateQuotation?.id) {
            this.dbQuotation.q_duplicate_barcode = duplicateQuotation.barcode;
            this.dbQuotation.quotation_duplicate = duplicateQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.duplicate"
            );
            this.dbQuotation.title = duplicateQuotation.title;
            this.dbQuotation.description = duplicateQuotation.description;
            this.dbQuotation.your_reference = duplicateQuotation.your_reference;
            this.dbQuotation.reference = duplicateQuotation.reference;
            this.dbQuotation.prepared_by_id = duplicateQuotation.prepared_by_id;
            this.dbQuotation.sales_executive =
              duplicateQuotation.sales_executive;
            this.dbQuotation.open_till = duplicateQuotation.open_till;
            this.dbQuotation.validity_till = duplicateQuotation.validity_till;
            this.dbQuotation.type = duplicateQuotation.type;
            this.dbQuotation.tags = duplicateQuotation.tags;
            this.dbQuotation.date = moment(duplicateQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = duplicateQuotation.rating;
            this.dbQuotation.attachments = duplicateQuotation.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              duplicateQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              duplicateQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = duplicateQuotation.client_note_id;
            this.dbQuotation.client_note = duplicateQuotation.client_note;

            this.dbQuotation.term_condition_id =
              duplicateQuotation.term_condition_id;
            this.dbQuotation.term_conditions =
              duplicateQuotation.term_conditions;

            this.dbQuotation.admin_note_id = duplicateQuotation.adminte_id;
            this.dbQuotation.admin_note = duplicateQuotation.admin_note;
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (requoteQuotation?.id) {
            this.dbQuotation.q_requote_barcode = requoteQuotation.barcode;
            this.dbQuotation.quotation_requote = requoteQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.requote"
            );
            this.dbQuotation.title = requoteQuotation.title;
            this.dbQuotation.description = requoteQuotation.description;
            this.dbQuotation.your_reference = requoteQuotation.your_reference;
            this.dbQuotation.reference = requoteQuotation.reference;
            this.dbQuotation.prepared_by_id = requoteQuotation.prepared_by_id;
            this.dbQuotation.sales_executive = requoteQuotation.sales_executive;
            this.dbQuotation.open_till = requoteQuotation.open_till;
            this.dbQuotation.validity_till = requoteQuotation.validity_till;
            this.dbQuotation.type = requoteQuotation.type;
            this.dbQuotation.tags = requoteQuotation.tags;
            this.dbQuotation.date = moment(requoteQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = requoteQuotation.rating;
            this.dbQuotation.attachments = requoteQuotation.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              requoteQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              requoteQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = requoteQuotation.client_note_id;
            this.dbQuotation.client_note = requoteQuotation.client_note;

            this.dbQuotation.term_condition_id =
              requoteQuotation.term_condition_id;
            this.dbQuotation.term_conditions = requoteQuotation.term_conditions;

            this.dbQuotation.admin_note_id = requoteQuotation.admin_note_id;
            this.dbQuotation.admin_note = requoteQuotation.admin_note;

            this.$store.commit(
              SET_CUSTOMER,
              requoteQuotation.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              requoteQuotation.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              requoteQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, requoteQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              requoteQuotation.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: duplicateQuotation.ctx_discount_label ?? null,
                ctx_discount_value: duplicateQuotation.ctx_discount_value ?? 0,
                ctx_discount_type: duplicateQuotation.ctx_discount_type ?? 1,
                discount_type: duplicateQuotation.discount_type ?? 1,
                discount_value: duplicateQuotation.discount_value ?? 0,
                tax_active: duplicateQuotation.tax_active ?? false,
                adjustment: duplicateQuotation.adjustment ?? 0,
                show_price: true,
                discount_level: duplicateQuotation.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, duplicateQuotation.line_item ?? []); */

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (reviseQuotation?.id) {
            this.dbQuotation.q_revise_barcode = reviseQuotation.barcode;
            this.dbQuotation.quotation_revise = reviseQuotation.id;
            this.updatedQuotationData = ObjectPath.get(response, "data.revise");
            this.dbQuotation.title = reviseQuotation.title;
            this.dbQuotation.description = reviseQuotation.description;
            this.dbQuotation.your_reference = reviseQuotation.your_reference;
            this.dbQuotation.reference = reviseQuotation.reference;
            this.dbQuotation.prepared_by_id = reviseQuotation.prepared_by_id;
            this.dbQuotation.sales_executive = reviseQuotation.sales_executive;
            this.dbQuotation.open_till = reviseQuotation.open_till;
            this.dbQuotation.validity_till = reviseQuotation.validity_till;
            this.dbQuotation.type = reviseQuotation.type;
            this.dbQuotation.tags = reviseQuotation.tags;
            this.dbQuotation.date = moment(reviseQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = reviseQuotation.rating;
            this.dbQuotation.attachments = reviseQuotation.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              reviseQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              reviseQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = reviseQuotation.client_note_id;
            this.dbQuotation.client_note = reviseQuotation.client_note;

            this.dbQuotation.term_condition_id =
              reviseQuotation.term_condition_id;
            this.dbQuotation.term_conditions = reviseQuotation.term_conditions;

            this.dbQuotation.admin_note_id = reviseQuotation.admin_note_id;
            this.dbQuotation.admin_note = reviseQuotation.admin_note;

            this.$store.commit(SET_CUSTOMER, reviseQuotation.customer_relation);
            this.$store.commit(SET_PROPERTY, reviseQuotation.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              reviseQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, reviseQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              reviseQuotation.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: duplicateQuotation.ctx_discount_label ?? null,
                ctx_discount_value: duplicateQuotation.ctx_discount_value ?? 0,
                ctx_discount_type: duplicateQuotation.ctx_discount_type ?? 1,
                discount_type: duplicateQuotation.discount_type ?? 1,
                discount_value: duplicateQuotation.discount_value ?? 0,
                tax_active: duplicateQuotation.tax_active ?? false,
                adjustment: duplicateQuotation.adjustment ?? 0,
                show_price: true,
                discount_level: duplicateQuotation.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, duplicateQuotation.line_item ?? []); */

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (enquiryQuotation?.id) {
            this.dbQuotation.enquiry_barcode = enquiryQuotation.barcode;
            this.dbQuotation.enquiry_id = enquiryQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.enquiry"
            );
            this.dbQuotation.title = enquiryQuotation.title;
            this.dbQuotation.your_reference = enquiryQuotation.reference;
            this.dbQuotation.prepared_by_id = enquiryQuotation.assign;

            this.dbQuotation.tags = enquiryQuotation.tags;

            this.dbQuotation.notify_billing_contact_person =
              enquiryQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              enquiryQuotation.notify_property_contact_person;
            this.dbQuotation.admin_note = enquiryQuotation.technician_sketch;

            this.$store.commit(
              SET_CUSTOMER,
              enquiryQuotation.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              enquiryQuotation.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              enquiryQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, enquiryQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              enquiryQuotation.billing_contact_person_relation
            );
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
  },
  mounted() {
    this.get_attributes();
    this.$store.dispatch(SET_BREADCRUMB, []);
    if (ObjectPath.get(this.$route, "query.enquiry") > 0) {
      this.related_type = 6;
      this.tag_type = "enquiry";
    } else {
      this.related_type = 1;
      this.tag_type = "quotation";
    }
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
    this.dbQuotation.enquiry = this.lodash.toSafeInteger(
      ObjectPath.get(this.$route, "query.enquiry")
    );
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
      "vSelectedLineItem",
      "vCalculations",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
