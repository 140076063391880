<template>
  <div>
    <template v-if="getFieldType == 'number'">
      <v-text-field
        v-bind="{
          ...$attrs,
        }"
        :class="{ 'error--text': isRequired && !textInputValue }"
        @keypress="($event) => validateNumber($event)"
        @paste="($event) => pasteValidateNumber($event)"
        v-model="textInputValue"
      ></v-text-field>
    </template>
    <template v-else-if="getFieldType == 'email'">
      <v-text-field
        v-model="textInputValue"
        v-bind="{
          ...$attrs,
        }"
        :class="{ 'error--text': isRequired && !textInputValue }"
        append-icon="mdi-email"
      ></v-text-field>
    </template>
    <template v-else-if="getFieldType == 'phone'">
      <PhoneTextField
        v-model="textInputValue"
        v-on:blur="isFocusedN = false"
        v-on:focus="isFocusedN = true"
      >
      </PhoneTextField>
    </template>
    <template v-else-if="getFieldType == 'date'">
      <DatePicker
        solo
        flat
        :default-date="textInputValue"
        v-model="textInputValue"
      >
      </DatePicker>
    </template>
    <template v-else-if="getFieldType == 'date-time'">
      <DateTimePicker v-model="textInputValue"></DateTimePicker>
    </template>
    <template v-else-if="getFieldType == 'checkbox'">
      <div :class="[{ 'd-flex flex-wrap': show_in_one_row }]">
        <div
          :class="`py-1 px-0 col-sm-${show_in_one_row ? row_items : 12}`"
          v-for="(item, index) in getItems"
          :key="item.value + index"
        >
          <v-checkbox
            class="pa-0"
            v-bind="{ ...$attrs }"
            v-model="textInputValue"
            :label="item.value"
            :value="item.value"
            color="cyan"
          ></v-checkbox>
        </div>
      </div>
    </template>
    <template v-else-if="getFieldType == 'radio'">
      <v-radio-group
        v-bind="{ ...$attrs }"
        v-model="textInputValue"
        :rules="[
          this.validateRules.required(
            textInputValue,
            getFieldType + ' default value'
          ),
        ]"
      >
        <div :class="[{ 'd-flex flex-wrap': show_in_one_row }]">
          <div
            :class="`py-1 px-0 col-sm-${show_in_one_row ? row_items : 12}`"
            v-for="(item, index) in getItems"
            :key="item.value + index"
          >
            <v-radio
              :label="item.value"
              :value="item.value"
              color="cyan"
            ></v-radio>
          </div>
        </div>
      </v-radio-group>
    </template>
    <template v-else-if="getFieldType == 'dropdown'">
      <v-autocomplete
        v-bind="{
          ...$attrs,
        }"
        :class="{ 'error--text': isRequired && !textInputValue }"
        :items="getItems"
        item-value="value"
        item-text="value"
        v-model="textInputValue"
      ></v-autocomplete>
    </template>
    <template v-else-if="getFieldType == 'textarea'">
      <TextAreaField
        v-bind="{
          ...$attrs,
        }"
        :class="{ 'error--text': isRequired && !textInputValue }"
        :counter="getMax"
        v-model="textInputValue"
      />
    </template>
    <template v-else-if="getFieldType == 'attachment'">
      <FileInput
        v-model="textInputValue"
        :validFileTypes="getValidFiles"
        :maxLimit="getMaxUploadLimit"
        :maxSize="getMaxUploadSize"
        :required="isRequired"
      >
      </FileInput>
    </template>
    <template v-else>
      <TextField
        v-bind="{
          ...$attrs,
        }"
        :class="{ 'error--text': isRequired && !textInputValue }"
        :counter="getMax"
        v-model="textInputValue"
      ></TextField>
    </template>
  </div>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileInput from "@/view/components/app-component/FileInput.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import DateTimePicker from "@/view/pages/partials/DateTimePicker.vue";
import { filter } from "lodash";
export default {
  name: "custom-text-input-field",
  inheritAttrs: false,
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number, Array, Date, Object],
      default: null,
    },
    fieldType: {
      type: String,
      default: "text",
    },
    row: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      textInputValue: null,
      isFocusedN: false,
    };
  },

  watch: {
    value: {
      deep: true,
      handler(param) {
        this.textInputValue = param;
      },
    },
    textInputValue() {
      this.$emit("input", this.textInputValue);
    },
  },
  methods: {
    pasteValidateNumber(event) {
      event.preventDefault();
    },
    validateNumber($event) {
      if (this.isDecimal) {
        this.limitDecimal($event, this.textInputValue);
      } else {
        this.isNumber($event);
      }
      if (this.getMax) {
        this.limitMaxLength($event, this.textInputValue, this.getMax);
      }
    },
    getRules(type) {
      const attr = this.$attrs;
      let rule = [...attr["rules"]];
      switch (type) {
        case "email":
          rule.push(
            this.validateRules.validEmail(this.textInputValue, "Email")
          );
      }
      return rule;
    },
  },
  computed: {
    counter() {
      const attr = this.$attrs;
      return attr.counter;
    },
    show_in_one_row() {
      return this.row;
    },
    row_items() {
      const attr = this.$attrs;
      return attr["row-items"];
    },
    getItems() {
      const attr = this.$attrs;
      const items = filter(attr.items, (row) => row.value);
      return items;
    },
    getMin() {
      const attr = this.$attrs;
      return attr.min;
    },
    getMax() {
      const attr = this.$attrs;
      if (attr.max) {
        return attr.max;
      }
      return 0;
    },
    getMaxUploadLimit() {
      const attr = this.$attrs;
      if (attr["max-upload-limit"]) {
        return attr["max-upload-limit"];
      }
      return 5;
    },
    getMaxUploadSize() {
      const attr = this.$attrs;
      if (attr["max-upload-size"]) {
        return attr["max-upload-size"];
      }
      return 5;
    },
    isDecimal() {
      const attr = this.$attrs;
      if (attr["decimal-allow"]) {
        return attr["decimal-allow"];
      }
      return false;
    },
    isRequired() {
      const attr = this.$attrs;
      if (attr["required"]) {
        return attr["required"];
      }
      return false;
    },
    getValidFiles() {
      const attr = this.$attrs;
      const _files = attr["valid-file"];
      const str_file = _files.join(",");
      return str_file.split(",");
    },
    getFieldType() {
      return this.fieldType;
    },
  },
  components: {
    PhoneTextField,
    DatePicker,
    DateTimePicker,
    TextAreaField,
    FileInput,
  },
  mounted() {
    this.textInputValue = this.value;
  },
};
</script>
