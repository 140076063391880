<template>
  <div>
    <v-textarea
      v-bind="{
        ...$attrs,
      }"
      :class="{ 'error--text': $attrs['required'] && !textAreaInput }"
      v-model="textAreaInput"
      v-on:keypress="(e) => manageLimit(e)"
      v-on:paste="(e) => onPaste(e)"
    ></v-textarea>
    <div class="text-right" v-if="counter">
      {{ textAreaInput ? textAreaInput.length : 0 }}/{{ counter }}
    </div>
  </div>
</template>
<script>
export default {
  name: "textarea-field",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      textAreaInput: null,
    };
  },

  watch: {
    value() {
      this.textAreaInput = this.value;
    },
    textAreaInput() {
      this.$emit("input", this.textAreaInput);
    },
  },
  methods: {
    manageLimit(e) {
      if (this.textAreaInput && this.textAreaInput.length > this.counter - 1) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let copied = e.clipboardData.getData("Text");
      let preVal = this.textAreaInput;
      let finalVal = String(preVal) + String(copied);
      if (Number(finalVal.length) > this.counter - 1) {
        let trimValue = finalVal.substring(0, this.counter);
        this.textAreaInput = trimValue;
        e.preventDefault();
      }
    },
  },
  computed: {
    counter() {
      const attr = this.$attrs;
      return attr.counter;
    },
  },
  mounted() {
    this.textAreaInput = this.value;
  },
};
</script>
