<template>
  <Dialog :common-dialog="vCustomerCreateDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex> Create new Customer </v-flex>
        <v-flex class="text-right">
          <v-btn
            class="custom-bold-button mr-4"
            depressed
            :disabled="
              steps.billing || !formValid || formLoading || isBillingValidated
            "
            :loading="formLoading"
            v-on:click="onSubmit()"
            color="cyan white--text"
            >Save &amp; Select</v-btn
          >
          <v-btn
            class="custom-bold-button custom-border"
            depressed
            :disabled="formLoading"
            v-on:click="closeDialog()"
            >Cancel</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="px-4 w-100">
        <v-form
          ref="customerForm"
          v-model="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-tabs
            v-model="customerTab"
            background-color="transparent"
            centered
            color="cyan"
            fixed-tabs
            class="custom-tab-transparent"
          >
            <template v-for="(tab, index) in tabs">
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                v-if="getPermission(tab.permission + ':create')"
                :key="index"
                :disabled="steps[tab.key]"
                :href="'#tab-' + tab.key"
              >
                <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
          <v-tabs-items v-model="customerTab">
            <v-tab-item :value="'tab-details'">
              <ContactDetails v-on:saveContactDetail="updateContactDetail" />
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('contact-person:create')"
              :value="'tab-contact-person'"
            >
              <ContactPersons
                v-bind:detail="contactDetail"
                v-on:saveContactPerson="updateContactPerson"
              />
            </v-tab-item>
            <v-tab-item
              v-if="getPermission('property:create')"
              :value="'tab-property'"
            >
              <PropertyAddress
                is-customer-from
                v-bind:billing="billingAddress"
                v-on:savePropertyAddress="updatePropertyAddress"
              />
            </v-tab-item>
            <v-tab-item :value="'tab-billing'">
              <BillingAddress
                v-bind:property="propertyAddress"
                v-on:saveBillingAddress="updateBillingAddress"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { POST, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import { SET_CC_PERSONS } from "@/core/services/store/common.module";
import { SET_CREATE_DIALOG_STATUS } from "@/core/services/store/customer.module";
import { SET_CUSTOMER } from "@/core/services/store/visit.module";
import ContactDetails from "@/view/pages/customer/create-or-update/Contact-Details.vue";
import ContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue";
import PropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import BillingAddress from "@/view/pages/customer/create-or-update/Billing-Address.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  name: "create-customer",
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      customerTab: false,
      isCustomerCreated: false,
      isAddressCreated: false,
      customerId: 0,
      steps: {
        details: false,
        "contact-person": true,
        property: true,
        billing: true,
        notification: true,
      },
      isBillingValidated: true,
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      tabs: [
        {
          title: "Contact Details",
          icon: "mdi-phone",
          key: "details",
          permission: "customer",
        },
        {
          title: "Contact Persons",
          icon: "mdi-account-multiple",
          key: "contact-person",
          permission: "contact-person",
        },
        {
          title: "Address",
          icon: "mdi-home-map-marker",
          key: "property",
          permission: "property",
        },
        {
          title: "Billing Address",
          icon: "mdi-credit-card",
          key: "billing",
          permission: "customer",
        },
      ],
    };
  },
  watch: {
    customerTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.customerForm.validate();
        _this.$nextTick(() => {
          _this.activateTab();
        });
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.activateTab();
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
  },
  components: {
    Dialog,
    ContactDetails,
    ContactPersons,
    PropertyAddress,
    BillingAddress,
  },
  methods: {
    activateTab() {
      if (this.formValid) {
        if (this.customerTab == "tab-details") {
          this.steps["contact-person"] = false;
        }

        if (this.customerTab == "tab-contact-person") {
          this.steps["property"] = false;
        }

        if (this.customerTab == "tab-property") {
          this.steps["billing"] = false;
        }

        if (this.customerTab == "tab-billing") {
          this.steps["notification"] = false;
          this.isBillingValidated = false;
        }
      }
    },
    updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
      this.$store.dispatch(SET_CC_PERSONS, param);
    },
    closeDialog() {
      this.$store.commit(SET_CREATE_DIALOG_STATUS, false);
    },
    createOrUpdatedAddress(customer) {
      return new Promise((resolve, reject) => {
        try {
          if (customer) {
            const _this = this;
            const addressArr = [];
            if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
              addressArr.push(_this.propertyAddress);
            }
            if (_this.lodash.isEmpty(_this.billingAddress) === false) {
              addressArr.push(_this.billingAddress);
            } else {
              addressArr.push({
                id: _this.propertyAddress.id || null,
                contact_person: _this.propertyAddress.contact_person || null,
                property_name: _this.propertyAddress.property_name || null,
                type: 2,
                is_same: 1,
                is_tenant: _this.propertyAddress.is_tenant || 0,
                unit_no: _this.propertyAddress.unit_no || null,
                street_1: _this.propertyAddress.street_1 || null,
                street_2: _this.propertyAddress.street_2 || null,
                zip_code: _this.propertyAddress.zip_code || null,
                country: _this.propertyAddress.country || null,
                latitude: _this.propertyAddress.latitude || null,
                longitude: _this.propertyAddress.longitude || null,
                remarks: _this.propertyAddress.remarks || null,
              });
            }

            if (_this.lodash.isEmpty(addressArr) === false) {
              const formData = { address: addressArr };
              _this.$store
                .dispatch(PUT, { url: "address/" + customer, data: formData })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    makeDefaultPerson() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let emailData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 1;
            }
          );
          let contactData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 2;
            }
          );
          let firstEntry = {
            id: null,
            title: _this.contactDetail.salutation,
            first_name: _this.contactDetail.first_name,
            last_name: _this.contactDetail.last_name,
            will_notified: 1,
            position: null,
            did: _this.contactDetail.did,
            display_name: _this.createDisplayName(),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.head(emailData).value
              : null,
            primary_phone: _this.lodash.head(contactData)
              ? _this.lodash.head(contactData).value
              : null,
            default: 1,
          };
          resolve([firstEntry]);
        } catch (error) {
          reject(error);
        }
      });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.contactDetail.first_name) {
        firstname = _this.contactDetail.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.contactDetail.last_name) {
        lastname = _this.contactDetail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.customerForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (_this.lodash.isEmpty(_this.propertyAddress)) {
          _this.customerTab = "tab-property";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Property address is required")
          );
          return false;
        }

        if (!_this.$refs.customerForm.validate()) {
          return false;
        }
        _this.$store.dispatch(CLEAR_ERROR, {});

        if (_this.lodash.isEmpty(_this.contactPerson)) {
          _this.contactPerson = await _this.makeDefaultPerson();
        }

        let formData = _this.contactDetail;

        if (_this.lodash.isEmpty(_this.contactDetail.profile_logo) === false) {
          //formData.profile_logo = _this.contactDetail.profile_logo.id;
        }
        /*  if (_this.contactDetail.customer_remark) { */
        formData.customer_remark = _this.contactDetail.customer_remark
          ? _this.contactDetail.customer_remark
          : null;
        /*  } */
        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          formData.persons = _this.contactPerson;
        }

        formData.client_reminders = 0;
        formData.client_follow_up_emails = 0;
        formData.print_email_on_work_order = 0;
        /*  formData.customer_remark = _this.options.remarks; */

        if (_this.lodash.isEmpty(_this.notification) === false) {
          formData.client_reminders = _this.lodash.toSafeInteger(
            _this.notification.client_reminders
          );
          formData.client_follow_up_emails = _this.lodash.toSafeInteger(
            _this.notification.client_follow_up_emails
          );
          formData.print_email_on_work_order = _this.lodash.toSafeInteger(
            _this.notification.print_email_on_work_order
          );
          /* formData.customer_remark = _this.lodash.toString(
            _this.notification.customer_remark
          ); */
        }

        _this.formLoading = true;
        try {
          if (!_this.isCustomerCreated) {
            let customerObject = await _this.$store.dispatch(POST, {
              url: "customer",
              data: formData,
            });
            _this.customerId = customerObject.data.id;
            _this.isCustomerCreated = true;
          }
        } catch (error) {
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            "Customer is not created. Please try again."
          );
          return false;
        }

        try {
          if (!_this.isAddressCreated) {
            await _this.createOrUpdatedAddress(_this.customerId);
            _this.isAddressCreated = true;
          }
        } catch (error) {
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            "Property or Company Address is not created. Please try again."
          );
          return false;
        }

        if (_this.isCustomerCreated) {
          _this.$store
            .dispatch(SET_CUSTOMER, { id: _this.customerId })
            .then(() => {
              _this.closeDialog();
            })
            .finally(() => {
              _this.closeDialog();
              _this.formLoading = false;
            });
        } else {
          _this.formLoading = false;
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  computed: {
    ...mapGetters(["vCustomerCreateDialog"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
