var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":12,"sm":12}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","sm":"12"}},[_c('v-row',[(!_vm.isDialog)?_c('v-col',{staticClass:"pt-0 d-flex",attrs:{"md":"12","sm":"12"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","true-value":1,"false-value":0,"color":"cyan","label":"Billing address is same as Address"},on:{"change":function($event){return _vm.copyAddress()}},model:{value:(_vm.billingAddress.is_same),callback:function ($$v) {_vm.$set(_vm.billingAddress, "is_same", $$v)},expression:"billingAddress.is_same"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6","sm":"6"}},[(false)?_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","label":"Get your current location","color":"cyan"},on:{"change":_vm.getUserCurrentAddress},model:{value:(_vm.trackGPS),callback:function ($$v) {_vm.trackGPS=$$v},expression:"trackGPS"}}):_vm._e()],1),(false)?_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"custom-google-autocomplete"},[_c('v-text-field',{attrs:{"id":"address-map","append-icon":"search","dense":"","filled":"","placeholder":"Search Address","solo":"","flat":"","color":"cyan","loading":_vm.autocompleteLoading},on:{"keyup":_vm.getGoogleAddress,"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.searchAddress),callback:function ($$v) {_vm.searchAddress=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchAddress"}}),(
                              (hover || _vm.isFocused) && _vm.autocompleteList.length
                            )?_c('v-list',{staticClass:"custom-google-autocomplete-list",attrs:{"two-line":"","elevation":"4"}},_vm._l((_vm.autocompleteList),function(address,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.selectGoogleAddress(index)}}},[_c('v-list-item-icon',{staticClass:"m-0 mr-3 my-auto"},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-map-marker ")])],1),(
                                  _vm.lodash.isEmpty(
                                    address.structured_formatting
                                  ) === false
                                )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.structured_formatting.main_text))]),_c('v-list-item-subtitle',{staticClass:"font-size-12"},[_vm._v(_vm._s(address.structured_formatting.secondary_text))])],1):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.description))])],1)],1)}),1):_vm._e()],1)]}}],null,false,3712967077)})],1):_vm._e()],1)],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Country ")]),_c('v-autocomplete',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.countryList,"hide-details":"","flat":"","rules":[
                      _vm.validateRules.required(
                        _vm.billingAddress.country,
                        'Country'
                      ),
                    ],"solo":"","dense":"","item-value":"value","item-text":"text","placeholder":"Country","color":"cyan","item-color":"cyan"},on:{"change":function($event){return _vm.countryCheck($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16"},[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.billingAddress.country),callback:function ($$v) {_vm.$set(_vm.billingAddress, "country", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.country"}})],1),_c('v-col',{staticClass:"mb-4",attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Search Address ")]),_c('SearchAddress',{attrs:{"variant":"outlined","density":"compact","hide-details":"","country":_vm.billingAddress.country,"country-code":_vm.country_code,"placeholder":_vm.billingAddress.country == 'singapore'
                        ? 'Search Address'
                        : 'Search Address'},on:{"update:address":(data) => _vm.setAddress(data)}})],1),_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Contact Person ")]),_c('v-select',{attrs:{"id":"contact-persons","items":_vm.cc_persons,"rules":[
                          _vm.validateRules.required(
                            _vm.billingAddress.contact_person,
                            'Contact Person'
                          ),
                        ],"dense":"","filled":"","item-text":"display_name","item-value":"uuid","item-color":"cyan","placeholder":"Contact Persons","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.contact_person),callback:function ($$v) {_vm.$set(_vm.billingAddress, "contact_person", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.contact_person"}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Site Location Name")]),_c('v-text-field',{attrs:{"rules":[
                          _vm.validateRules.maxLength(
                            _vm.billingAddress.property_name,
                            'Site Location Name.',
                            100
                          ),
                          _vm.validateRules.required(
                            _vm.billingAddress.property_name,
                            'Site Location Name'
                          ),
                        ],"dense":"","filled":"","placeholder":"Site Location Name.","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.property_name),callback:function ($$v) {_vm.$set(_vm.billingAddress, "property_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.property_name"}})],1)],1)],1),(_vm.billingAddress.country == 'singapore')?[_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[(_vm.billingAddress.country == 'singapore')?[_vm._v(" Postal Code ")]:[_vm._v(" Find Address ")]],2),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"rules":[
                        _vm.validateRules.required(
                          _vm.billingAddress.zip_code,
                          'Postal Code'
                        ),
                        _vm.validateRules.maxLength(
                          _vm.billingAddress.zip_code,
                          'Postal Code',
                          6
                        ),
                        _vm.validateRules.minLength(
                          _vm.billingAddress.zip_code,
                          'Postal Code',
                          2
                        ),
                      ],"dense":"","filled":"","placeholder":"Postal Code","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.zip_code),callback:function ($$v) {_vm.$set(_vm.billingAddress, "zip_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.zip_code"}})],1)]:[(_vm.billingAddress.country != 'singapore')?_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Full Address")]),_c('v-textarea',{attrs:{"rules":[
                        _vm.validateRules.required(
                          _vm.billingAddress.street_1,
                          'Full Address'
                        ),
                        _vm.validateRules.maxLength(
                          _vm.billingAddress.street_1,
                          'Full Address',
                          255
                        ),
                      ],"auto-grow":"","dense":"","filled":"","placeholder":"Full Address","solo":"","flat":"","row-height":"15","color":"cyan"},model:{value:(_vm.billingAddress.street_1),callback:function ($$v) {_vm.$set(_vm.billingAddress, "street_1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.street_1"}})],1):_vm._e(),(_vm.billingAddress.country == 'singapore')?_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Postal Code")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"rules":[
                        _vm.validateRules.required(
                          _vm.billingAddress.zip_code,
                          'Postal Code'
                        ),
                        _vm.validateRules.maxLength(
                          _vm.billingAddress.zip_code,
                          'Postal Code',
                          6
                        ),
                        _vm.validateRules.minLength(
                          _vm.billingAddress.zip_code,
                          'Postal Code',
                          2
                        ),
                      ],"dense":"","filled":"","placeholder":"Postal Code","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.zip_code),callback:function ($$v) {_vm.$set(_vm.billingAddress, "zip_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.zip_code"}})],1):_vm._e()],(_vm.billingAddress.country == 'singapore')?_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Unit No.")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.unit_no,
                        'Unit No.',
                        100
                      ),
                    ],"dense":"","filled":"","placeholder":"Unit No.","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.unit_no),callback:function ($$v) {_vm.$set(_vm.billingAddress, "unit_no", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.unit_no"}})],1):_vm._e(),(_vm.billingAddress.country == 'singapore')?_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Address Line 1")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.required(
                        _vm.billingAddress.street_1,
                        'Address Line 1'
                      ),
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.street_1,
                        'Address Line 1',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Address Line 1","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.street_1),callback:function ($$v) {_vm.$set(_vm.billingAddress, "street_1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.street_1"}})],1):_vm._e(),(_vm.billingAddress.country == 'singapore')?_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Address Line 2")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.street_2,
                        'Address Line 2',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Address Line 2","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.street_2),callback:function ($$v) {_vm.$set(_vm.billingAddress, "street_2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.street_2"}})],1):_vm._e(),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.latitude,
                        'Latitude',
                        100
                      ),
                    ],"dense":"","filled":"","placeholder":"Latitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.latitude),callback:function ($$v) {_vm.$set(_vm.billingAddress, "latitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.latitude"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.longitude,
                        'Longitude',
                        100
                      ),
                    ],"dense":"","filled":"","placeholder":"Longitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.billingAddress.longitude),callback:function ($$v) {_vm.$set(_vm.billingAddress, "longitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.longitude"}})],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Remark For Address")]),_c('v-textarea',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.billingAddress.remarks,
                        'Remarks',
                        512
                      ),
                    ],"auto-grow":"","dense":"","filled":"","placeholder":"Remarks","solo":"","flat":"","row-height":"15","color":"cyan"},on:{"keypress":(e) => _vm.manageLimitdescr(e),"paste":(e) => _vm.onPaste(e)},model:{value:(_vm.billingAddress.remarks),callback:function ($$v) {_vm.$set(_vm.billingAddress, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"billingAddress.remarks"}}),_c('div',{staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(_vm.billingAddress.remarks ? _vm.billingAddress.remarks.length : 0)+"/200 ")])],1)],2)],1)],1)],1)],1)],1)],1),(false && !_vm.isDialog)?[_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }