<template>
  <v-menu
    ref="datePicker"
    v-model.trim="startDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    left
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        :label="lodash.capitalize(placeholder)"
        :rules="
          mandatory
            ? [() => !!datePicker.date || placeholder + ' is required']
            : []
        "
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="!solo"
        :solo="solo"
        :flat="solo"
        :loading="pageLoading"
        :disabled="disabled"
        :hide-details="!showDetails"
        :clearable="clearable"
        :value="formattedStartDate"
        color="cyan"
        v-on:click:clear="clearField()"
        :class="fieldClass"
      ></v-text-field>
    </template>
    <v-date-picker
      left
      no-title
      :allowed-dates="
        offWeekend
          ? allowedDates
          : (event) => {
              return event;
            }
      "
      :disabled="disabled"
      v-model.trim="datePicker.date"
      color="cyan"
      v-on:input="startDatePicker = false"
      v-on:change="saveDate"
      :min="minDate"
      @update:picker-date="pickerUpdate($event)"
      :max="maxDate"
      :readonly="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "date-picker",
  model: {
    prop: "value",
    event: "input",
  },
  data: () => {
    return {
      pickerType: null,
      startDatePicker: false,
      availableDates: [],
      datePicker: {
        date: new Date().toISOString().substr(0, 10),
      },
    };
  },
  props: {
    defaultDate: {
      type: String,
      default: () => {
        return new Date().toISOString().substr(0, 10);
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return "Date";
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    minDate: {
      type: String,
      default: undefined,
    },
    maxDate: {
      type: String,
      default: undefined,
    },
    activePicker: {
      type: String,
      default: "DATE",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Date, String],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    removeBorder: {
      type: Boolean,
      default: false,
    },
    offWeekend: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.nullable) {
      this.datePicker.date = this.defaultDate || "";
      this.$emit("update:date-picker", this.datePicker.date);
      this.$emit("input", this.datePicker.date);
    } else {
      this.datePicker.date = "";
    }
  },
  methods: {
    clearField() {
      this.$emit("click:clear", true);
      this.datePicker.date = null;
    },
    saveDate(date) {
      this.$emit("change", date);
      this.$refs.datePicker.save(date);
    },
    allowedDates(a) {
      return this.availableDates.includes(a);
    },

    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();
      console.log(totalDay);

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0 && moment(date).day() !== 6)
          availableDates.push(date);
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
  },
  watch: {
    startDatePicker(val) {
      const _this = this;
      val && setTimeout(() => (_this.pickerType = _this.activePicker));
    },
    defaultDate() {
      if (!this.nullable) {
        this.datePicker.date = this.defaultDate || "";
      }
    },
    value(param) {
      if (!param) {
        this.datePicker.date = null;
      }
    },
    nullable(param) {
      if (param) {
        this.datePicker.date = "";
      }
    },
    datePicker: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("update:date-picker", param.date);
        this.$emit("input", param.date);
      },
    },
  },
  computed: {
    fieldClass() {
      let result = this.customClass;
      if (this.removeBorder) {
        result = result + "remove-input-border";
      }
      return result;
    },
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        if (!this.datePicker.date) {
          if (this.nullable) {
            return null;
          }
          return moment().format(Config.dateFormat);
        }
        return moment(this.datePicker.date).format(Config.dateFormat);
      }
      return this.lodash.cloneDeep(this.datePicker.date);
    },
  },
};
</script>
