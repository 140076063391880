<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          <template v-if="lodash.isEmpty(warrantyDetail) === false">
            Update Warranty
          </template>
          <template v-else> Add Warranty</template>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 90vh; position: relative"
          >
            <v-form
              ref="warrantyForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="updateWarranty"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Duration</label>
                    <v-text-field
                      filled
                      label="Duration"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      dense
                      v-model.trim="warrantyOption.duration"
                      v-mask="'###'"
                      v-on:keyup="
                        (e) => updateWarrantyDuration(e, 'warranty_duration')
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Duration Type</label>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      v-model.trim="warrantyOption.duration_type"
                      solo
                      flat
                      class="pl-2 pr-2 width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="
                        (e) =>
                          updateWarrantyDuration(e, 'warranty_duration_type')
                      "
                    ></v-select>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Start Date</label>
                    <DatePicker
                      :defaultDate="warrantyOption.start_date"
                      solo
                      :pageLoading="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="warrantyOption.start_date"
                      v-on:change="
                        (e) => updateWarrantyDuration(e, 'start_date')
                      "
                    ></DatePicker>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">End Date </label>
                    <DatePicker
                      :defaultDate="warrantyOption.end_date"
                      solo
                      :pageLoading="pageLoading"
                      :placeholder="'End Date'"
                      v-model="warrantyOption.end_date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1 required"
                      >Serial No.</label
                    >
                    <v-text-field
                      dense
                      filled
                      :rules="[
                        validateRules.required(
                          warrantyOption.unique_id,
                          'Serial No.'
                        ),
                      ]"
                      color="cyan"
                      label="Serial No."
                      solo
                      flat
                      v-model.trim="warrantyOption.unique_id"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Cost</label>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      solo
                      label="Cost"
                      flat
                      v-model.trim="warrantyOption.cost"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <label class="font-weight-600 ml-1">Description</label>
                    <v-textarea
                      v-model.trim="warrantyOption.description"
                      auto-grow
                      dense
                      filled
                      solo
                      flat
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                      color="cyan"
                      label="Description"
                      row-height="20"
                    ></v-textarea>
                    <div class="text-right">
                      {{
                        warrantyOption.description
                          ? warrantyOption.description.length
                          : 0
                      }}/200
                    </div>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <template v-for="(file, index) in files">
                      <v-row :key="index">
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0 d-flex align-items-center"
                        >
                          <v-file-input
                            :id="`document-file-${index}`"
                            placeholder="Select File"
                            outlined
                            class="mt-3"
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment"
                            hide-details
                            v-model="file.file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0 d-flex align-items-center"
                        >
                          <v-text-field
                            :id="`document-name-${index}`"
                            class="mt-3"
                            filled
                            placeholder="File Name"
                            solo
                            flat
                            color="cyan"
                            hide-details
                            v-model="file.name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          lg="1"
                          cols="1"
                          class="my-auto py-0"
                          style="text-align: center"
                        >
                          <v-btn
                            :disabled="files.length < 2"
                            v-on:click="removeFile(index)"
                            color="red lighten-1 white--text"
                            class="mx-2"
                            icon
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col lg="1" cols="1" class="my-auto py-0">
                          <v-btn
                            class="mx-2 custom-bold-button white--text"
                            color="cyan"
                            tile
                            depressed
                            v-on:click="addMore()"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="update_or_create"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import moment from "moment";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import {
  POST,
  PUT,
  CLEAR_ERROR,
  QUERY,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { toSafeInteger, cloneDeep } from "lodash";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    /*   allCustomerList: {
        type: Array,
        default: () => {
          return new Array();
        },
      },
      allEquipemtList: {
        type: Array,
        default: () => {
          return new Array();
        },
      }, */
    value: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    warrantyDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    DatePicker,
  },
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.getOptions();
          this.updateWarrantyDuration(this.warrantyOption.start_date);
        }
      },
    },
  },
  data() {
    return {
      barcode: null,
      formValid: true,
      pageLoading: false,
      startDatePicker: null,
      defaultItemWarranty: new Array(),
      /*  allCustomerList: [],
        equipmemntArr: {},
        allEquipemtList: [], */
      files: [
        {
          file: null,
          name: null,
        },
      ],
      warrantyOption: new Object({
        id: null,
        cost: 0,
        warranty_type: "customer",
        start_date: null,
        duration: 1,
        duration_type: "month",
        end_date: null,
        unique_id: null,
        description: null,
      }),

      warrantyDurationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        /* {
            text: "Year(s)",
            value: "year",
          }, */
      ],
      /*   warrantyList: [
          { text: "30 Days", value: "30", type: "day" },
          { text: "6 Months", value: "182", type: "day" },
          { text: "1 Year", value: "365", type: "day" },
          { text: "2 Years", value: "730", type: "day" },
          { text: "3 Years", value: "1095", type: "day" },
        ], */
      /*   warrantyDurationTypes: [
          { text: "Day(s)", value: "day" },
          { text: "Week(s)", value: "week" },
          { text: "Month(s)", value: "month" },
          { text: "Year(s)", value: "year" },
        ], */
    };
  },
  methods: {
    clearAll() {
      this.warrantyOption.customer = 0;
      this.warrantyOption.equipemt = 0;
      /*  this.getAllCustomers();
        this.getCustomerEquipment(); */
    },
    getOptions(id) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "warranty/options",
          data: { customer: id ? id : 0 },
        })
        .then(({ data }) => {
          _this.barcode = data;
          this.warrantyOption.unique_id = _this.barcode.barcode;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateWarrantyDuration(e, type) {
      let warranty_start_date = e;
      if (type != "start_date") {
        warranty_start_date =
          this.warrantyOption && this.warrantyOption.start_date
            ? this.warrantyOption.start_date
            : null;
      }
      if (warranty_start_date) {
        let date = moment(warranty_start_date, "YYYY-MM-DD");

        let duration = this.warrantyOption.duration
          ? toSafeInteger(this.warrantyOption.duration)
          : 0;
        switch (this.warrantyOption.duration_type) {
          case "day":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
            break;
        }
        /* this.$nextTick(() => {
                      this.warranty.warranty_end_date = date.format("YYYY-MM-DD");
                  }); */
        this.warrantyOption.end_date = cloneDeep(date.format("YYYY-MM-DD"));
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.warrantyOption.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.warrantyOption.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.warrantyOption.description &&
        this.warrantyOption.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    updateFile(index, file, value, data) {
      if (value == "keyword") {
        if (file && file.name) {
          data[index].name = file.name.split(".").slice(0, -1).join(".");
          data[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          data[index].name = null;
          data[index].suffix = null;
        }
      } else {
        if (file && file.name) {
          this.files[index].name = file.name.split(".").slice(0, -1).join(".");
          this.files[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          this.files[index].name = null;
          this.files[index].suffix = null;
        }
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },

    update_or_create() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }
      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      /*  let formData = new FormData();
        formData.append(
          "type",
          this.warrantyOption.warrantyOption
            ? this.warrantyOption.warrantyOption
            : "customer"
        );
        formData.append(
          "type_id",
          this.warrantyOption.customer ? this.warrantyOption.customer : 0
        );
        formData.append(
          "service_id",
          this.warrantyOption.equipemt ? this.warrantyOption.equipemt : 0
        );
        formData.append(
          "duration",
          this.warrantyOption.duration ? this.warrantyOption.duration : 0
        );
        formData.append(
          "duration_type",
          this.warrantyOption.duration_type
            ? this.warrantyOption.duration_type
            : "day"
        );
        if (this.warrantyOption && this.warrantyOption.start_date) {
          formData.append(
            "start_date",
            this.warrantyOption.start_date ? this.warrantyOption.start_date : null
          );
        }
        if (this.warrantyOption && this.warrantyOption.end_date) {
          formData.append(
            "end_date",
            this.warrantyOption.end_date ? this.warrantyOption.end_date : null
          );
        }
        if (this.warrantyOption && this.warrantyOption.cost) {
          formData.append(
            "cost",
            this.warrantyOption.cost ? this.warrantyOption.cost : 0
          );
        }
        if (this.warrantyOption && this.warrantyOption.description) {
          formData.append(
            "remark",
            this.warrantyOption.description
              ? this.warrantyOption.description
              : null
          );
        }
        if (this.warrantyOption && this.warrantyOption.unique_id) {
          formData.append(
            "unique_id",
            this.warrantyOption.unique_id ? this.warrantyOption.unique_id : null
          );
        }
        for (let i = 0; i < this.files.length; i++) {
          if (this.files && this.files[i] && this.files[i].file) {
            formData.append(`file[${i}][file]`, this.files[i].file);
            formData.append(`file[${i}][name]`, this.files[i].name);
          }
        } */
      console.log(this.warrantyOption, "value");
      this.$emit("warrnty:data", this.warrantyOption);
      this.$emit("close:dialog");
      this.pageLoading = false;
      /*  let requestTYPE = POST;
        let requestURL = "warranty";
        this.$store
          .dispatch(requestTYPE, {
            url: requestURL,
            data: formData,
          })
          .then(() => {
            _this.$emit("update:warranty", true);
            _this.$emit("close:dialog");
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.pageLoading = false;
          }); */
    },
    updateWarranty() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = new Object({
        id: _this.lodash.toSafeInteger(_this.warrantyOption.id) || null,
        customer:
          _this.lodash.toSafeInteger(_this.warrantyOption.customer) || null,
        product:
          _this.lodash.toSafeInteger(_this.warrantyOption.product) || null,
        start_date: _this.warrantyOption.start_date,
        unique_id: _this.warrantyOption.unique_id,
        description: _this.warrantyOption.description,
        warranty_data: _this.defaultItemWarranty,
        duration: _this.defaultItemWarranty,
      });

      let requestType = POST;
      if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    /*  warrantDefaultStartDate() {
  que_id,
          description: _this.warrantyOption.description,
          warranty_data: _this.defaultItemWarranty,
          duration: _this.defaultItemWarranty,
        });
  
        let requestType = POST;
        if (_this.lodash.toSafeInteger(formData.id) > 0) {
          requestType = PUT;
        }
  
        _this.$store
          .dispatch(requestType, { url: "warranty", data: formData })
          .then(() => {
            _this.$emit("update:warranty", true);
            _this.$emit("close:dialog");
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      },
    },
    computed: {
     /*  warrantDefaultStartDate() {
   if (_this.lodash.toSafeInteger(formData.id) > 0) {
          requestType = PUT;
        }
  
        _this.$store
          .dispatch(requestType, { url: "warranty", data: formData })
          .then(() => {
            _this.$emit("update:warranty", true);
            _this.$emit("close:dialog");
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      },
    },
    computed: {
     /*  warrantDefaultStartDate() {
     if (_this.lodash.toSafeInteger(formData.id) > 0) {
          requestType = PUT;
        }
  
        _this.$store
          .dispatch(requestType, { url: "warranty", data: formData })
          .then(() => {
            _this.$emit("update:warranty", true);
            _this.$emit("close:dialog");
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      },
    },
    computed: {
     /*  warrantDefaultStartDate() {
        return this.lodash.isEmpty(this.warrantyDetail) === false
          ? this.warrantyDetail.warranty_start_date
          : new Date().toISOString().substr(0, 10);
      }, */
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
